
import TermsOfService from '@/views/TermsOfService.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import Vue from 'vue';
import qr from '@/assets/images/qr.png';
import cardFrontImg from '@/assets/images/products/svg/cardStandardBlackNoName.svg';
import cardRearImg from '@/assets/images/products/svg/cardStandardBlackNoName.svg';
import websiteQrCode from '@/assets/images/products/svg/websiteQrCode.svg';
import { SAMLAuthProvider, FacebookAuthProvider, GoogleAuthProvider, sendEmailVerification, signInWithCredential, OAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { signOutAccount } from '../util-functions/auth-utils';
import { currFirestore, fbAuth } from '../util-functions/initialization-utils';
import { hideLoading, showLoading, showLoadingBloop } from '../util-functions/loading-utils';
import { showError } from '../util-functions/misc-firestore-utils';
import { getParameterByName } from '../util-functions/misc-utils';
import { doc, getDoc } from 'firebase/firestore';
import { showConfirmation, showNotice } from '@/util-functions/notice-utils';
import { t } from '@/util-functions/language-utils';
import { eInteractionDeviceTypes } from '@/enums';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';
import validationRules from '@/validation-rules';

const umbProvider = new SAMLAuthProvider('saml.umb');
const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');

if (navigator.languages !== undefined) {
  appleProvider.setCustomParameters({
    // Localize the Apple authentication screen.
    locale: navigator.languages[0],
  });
}

export default Vue.extend({
  components: {
    TermsOfService,
    PrivacyPolicy,
  },
  data(): {
    qr: typeof qr;
    cardFrontImg: typeof cardFrontImg;
    cardRearImg: typeof cardRearImg;
    cardFlipped: boolean;
    signInFormInitialized: boolean;
    showPrivacyPolicy: boolean;
    showTermsOfService: boolean;
    isInPairingMode: boolean;
    currProduct: string;
    isNativePlatform: boolean;
    rules: typeof validationRules;
    showPasswordReset: boolean;
    showPassword: boolean;
    showSignUpForm: boolean;
    showSignInWithEmailForm: boolean;
    emailForPasswordReset: string;
    signUpForm: {
      email: string;
      displayName: string;
      password: string;
      passwordConfirm: string;
    };
    signInform: {
      email: string;
      password: string;
    };
    websiteQrCode: string;
  } {
    return {
      qr,
      cardFrontImg,
      cardRearImg,
      cardFlipped: false,
      signInFormInitialized: false,
      showPrivacyPolicy: false,
      showTermsOfService: false,
      isInPairingMode: Boolean(getParameterByName('g')),
      currProduct: '',
      isNativePlatform: Capacitor.isNativePlatform(),
      rules: validationRules,
      showPasswordReset: false,
      showPassword: false,
      showSignUpForm: false,
      showSignInWithEmailForm: false,
      emailForPasswordReset: '',
      signUpForm: {
        email: '',
        displayName: '',
        password: '',
        passwordConfirm: '',
      },
      signInform: {
        email: '',
        password: '',
      },
      websiteQrCode,
    };
  },
  mounted() {
    if (this.isInPairingMode) {
      getDoc(doc(currFirestore, 'genericIdToUserIdMappings', getParameterByName('g'))).then((docSnap) => {
        if (docSnap.exists()) {
          const genericIdData = docSnap.data() as GenericIdData;
          if (genericIdData.dateActivated) {
            showNotice(t.cannotLinkAccountAlreadyActivated);
            return;
          }
          switch (genericIdData.interactionDeviceType) {
            case eInteractionDeviceTypes.blackSitchBand:
              this.currProduct = 'Sitch Band';
              break;
            case eInteractionDeviceTypes.blackSitchDot:
              this.currProduct = 'Sitch Dot';
              break;
            case eInteractionDeviceTypes.blackSitchBumper:
              this.currProduct = 'Sitch Bumper';
              break;
            case eInteractionDeviceTypes.square16mmx16mmStickerPackSize5:
              this.currProduct = 'Sitch Sticker';
              break;
            case eInteractionDeviceTypes.smartLink:
              this.currProduct = 'Sitch Smart Link';
              break;
            case eInteractionDeviceTypes.genericNfcCard:
              this.currProduct = 'NFC Card';
              break;
            case eInteractionDeviceTypes.zgoda:
              this.currProduct = 'Zgoda Creations Card';
              break;
            default:
              this.currProduct = 'Sitch Card';
              window.setTimeout(() => {
                this.cardFlip();
              }, 100);
              break;
          }
        } else {
          showNotice(t.cannotLinkAccountIdNotValid);
        }
      });
    }
    document.addEventListener('keypress', this.onKeyPress);
  },
  beforeDestroy() {
    document.removeEventListener('keypress', this.onKeyPress);
  },
  watch: {
    isLoggedIn(val) {
      if (val) {
        this.resetFormData();
      }
    },
    showSignUpForm() {
      if (this.$refs.signInForm) (this.$refs.signInForm as any).reset();
      if (this.$refs.signUpForm) (this.$refs.signUpForm as any).reset();
    },
    showSignInWithEmailForm() {
      Vue.nextTick(() => {
        const field = (this.$refs as any).emailSignInEmailField as HTMLInputElement;
        if (field) {
          field.focus();
        }
      });
    },
  },
  computed: {
    isLoggedIn(): boolean {
      return this.$store.state.isLoggedIn;
    },
    comparePasswordRules(): string | boolean {
      return this.signUpForm.password !== this.signUpForm.passwordConfirm ? 'Passwords do not match' : true;
    },
  },
  methods: {
    showNotice(str = '') {
      showNotice(str);
    },
    onKeyPress(event: KeyboardEvent) {
      // Block enter key submission if a dialoge is shown on top of sign in form.
      if (!this.isLoggedIn && event.key === 'Enter') {
        event.preventDefault();
        if (this.showPasswordReset) {
          this.onResetPassword();
        } else if (this.showSignUpForm) {
          this.signUp();
        } else {
          this.signInWithEmail();
        }
      }
    },
    resendVerificationEmail() {
      sendEmailVerification(this.$store.state.firebaseUser)
        .then(() => {
          showNotice(t.emailSent);
        })
        .catch((error) => {
          if (error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER') {
            showError(t.tooManyAttempts);
          } else {
            showError(t.somethingWentWrong);
          }
        });
    },
    cardFlip() {
      this.cardFlipped = !this.cardFlipped;
    },
    signInWithGoogle() {
      showLoadingBloop();
      FirebaseAuthentication.signInWithGoogle().then((result) => {
        const credential = GoogleAuthProvider.credential(result.credential?.idToken);
        signInWithCredential(fbAuth, credential).catch((err) => {
          showError(err);
        });
      });
    },
    async signInWithFacebook() {
      showLoadingBloop();
      FirebaseAuthentication.signInWithFacebook().then((result) => {
        if (result.credential?.accessToken) {
          const credential = FacebookAuthProvider.credential(result.credential?.accessToken);
          signInWithCredential(fbAuth, credential).catch((err) => {
            showError(err);
          });
        } else {
          showError('No Facebook accessToken provided.');
        }
      });
    },
    signInWithApple() {
      showLoadingBloop();
      FirebaseAuthentication.signInWithApple({
        skipNativeAuth: true,
      }).then((result) => {
        const provider = appleProvider;
        const credential = provider.credential({
          idToken: result.credential?.idToken,
          rawNonce: result.credential?.nonce,
        });
        signInWithCredential(fbAuth, credential).catch((err) => {
          showError(err);
        });
      });
    },
    signInWithUmbLogin() {
      showLoadingBloop();
      if (this.isNativePlatform) {
        showConfirmation('UMB sign up is currently only available on our web apps. Install our PWA for this option.');
        return;
      }
      showLoadingBloop();
      const provider = umbProvider;
      signInWithPopup(fbAuth, provider);
    },
    signInWithEmail() {
      const form = this.$refs.signInForm as any;
      if (form?.validate()) {
        if (this.signInform.email.includes('@umaryland.edu')) {
          showConfirmation('UMB has prohibited non UMB login sign-ins for UMB accounts. Use the UMB login option instead.');
          return;
        }

        showLoading();
        signInWithEmailAndPassword(fbAuth, this.signInform.email, this.signInform.password)
          .then((authUser) => {
            if (!authUser.user) {
              showError('No user object on auth user for sign in.');
            }
          })
          .catch((error: any) => {
            switch (error.code) {
              case 'auth/wrong-password':
                showError('Invalid username/password.');
                break;
              case 'auth/too-many-requests':
                showError('Too many failed attempts. Reset your password to gain access now or you can try again later.');
                break;
              case 'auth/user-not-found':
                showError('Email not found. Sign up below.');
                break;
              default:
                showError(t.somethingWentWrong, error);
                break;
            }
          })
          .finally(() => {
            hideLoading();
          });
      }
    },
    onResetPassword() {
      const form = this.$refs.resetForm as any;
      if (form?.validate()) {
        FirebaseAuthentication.sendPasswordResetEmail({ email: this.emailForPasswordReset })
          .then(() => {
            showNotice(t.resetPasswordNotice.supplant([this.emailForPasswordReset]));
            this.showPasswordReset = false;
          })
          .catch((error: any) => {
            showError('Could not reset password.' + (error.message || error));
          });
      }
    },
    signUp() {
      const form = this.$refs.signUpForm as any;
      if (form?.validate()) {
        if (this.signUpForm.email.includes('@umaryland.edu')) {
          showConfirmation('UMB has prohibited non UMB login sign-ins for UMB accounts. Use the UMB login option instead.');
          return;
        }

        this.$store.commit('dataForEmailSignUp', {
          displayName: this.signUpForm.displayName,
        });
        FirebaseAuthentication.createUserWithEmailAndPassword({ email: this.signUpForm.email, password: this.signUpForm.password })
          .then(() => {
            FirebaseAuthentication.updateProfile({
              displayName: this.signUpForm.displayName,
            });
          })
          .catch((error: any) => {
            if (error.code === 'auth/email-already-in-use') {
              showError('Email already in use.');
            } else {
              signOutAccount();
              showError('There was a problem creating the user account.', error);
            }
          });
      }
    },
    resetFormData() {
      this.signUpForm.email = '';
      this.signUpForm.displayName = '';
      this.signUpForm.password = '';
      this.signUpForm.passwordConfirm = '';
      this.signInform.email = '';
      this.signInform.password = '';
    },
  },
});
